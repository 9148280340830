import React from "react";
import { useState} from "react";
import SlideFromBottom from "../Animation/SlideFromBottom";
import Modal from "../ui/Modal";
export default function PersonCard({
  imageURL,
  name,
  position,
  motif,
}: {
  imageURL: string;
  name: string;
  position: string;
  motif: string;
}) {
  const [showModal, setShowModal] = React.useState(false);
  return (
    <SlideFromBottom>
{showModal ? (
        <>
          <div
            className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed z-50  right-10 left-10 outline-none focus:outline-none"
          >
            <div className="relative w-auto my-6 mx-auto max-w-3xl">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}
                <div className="flex items-start justify-between p-4 border-b border-solid border-blueGray-200 rounded-t">
                  <h3 className="text-2xl font-semibold">
                  {name}
                  </h3>
                  <button className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none" onClick={() => setShowModal(false)}>
                    <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                      ×
                    </span>
                  </button>
                </div>
                <div className="relative p-6 flex-auto">
                  <p className="my-4 text-blueGray-500 text-lg leading-relaxed">
                    {/* {motif} */}
                    <div dangerouslySetInnerHTML={{ __html: motif }} />
                  </p>
                </div>
                {/*footer*/}
                <div className="flex items-center justify-end p-2 border-t border-solid border-blueGray-200 rounded-b">
                  <button className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button"onClick={() => setShowModal(false)}>Inchide</button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
      <div className="w-full h-full px-4 pt-8 pb-8 bg-white rounded-md shadow-xl flex flex-col items-center text-black gap-2 transition-all cursor-pointer hover:scale-105">
        <div className="relative w-[50%] h-0 pb-[50%] bg-black/5 rounded-full overflow-hidden">
          <img className="absolute left-0 top-0 w-full" src={imageURL} alt=""/>
        </div>
        <div className="h-[1px] border border-black/5 w-[75%] my-8" />
        <label className="text-2xl font-semibold">{name}</label>
        <label className="font-bold text-center text-black/50 uppercase">
          {position}
        </label>
        <button className="px-12 bg-blue-500 text-white active:bg-blue-600 font-bold uppercase text-sm px-6 py-1 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mt-auto ease-linear transition-all duration-150" type="button"onClick={() => setShowModal(true)} >Detalii</button>
      </div>
    </SlideFromBottom>
  );
}
