import React from "react";
import ReactDOM from "react-dom/client";
import TagManager from "react-gtm-module";
import "./index.css";
import App from "./App";
const tagManagerArgs = {
    gtmId: 'G-Z43D4TQMFG'
}
TagManager.initialize(tagManagerArgs)

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(<App />);
