import React from "react";
import Reveal from "../../components/Animation/Reveal";
import Container from "../../components/ui/Container";
import TVCard from "../../components/ui/TVCard";

import image from "../../assets/undraw_programming_re_kg9v.svg";
import obsph from "../../assets/sponsors/obsPrahoveanLogo.png";

export default function Televizari() {
  return (
    <div className="py-2 flex flex-col items-center">
      <Reveal>
        <label className="text-4xl lg:text-6xl font-light pb-6">
           Apariții Media
        </label>
      </Reveal>

      <Container className="grid grid-cols-1 md:grid-cols-3 gap-y-10 gap-x-5 p-10 ">
            <TVCard imageURL={obsph} mediaURL="https://www.observatorulph.ro/it-c/2702737-crearea-unor-dispozitive-care-sa-masoare-calitatea-aerului-si-sa-ofere-internet-gratuit-provocarea-primului-maraton-de-programare-de-la-ploiesti"/>
      </Container>
    </div>
  );
}
