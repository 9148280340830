import React from "react";
import Reveal from "../../components/Animation/Reveal";
import Container from "../../components/ui/Container";
import PersonCard from "../../components/ui/PersonCard";

import image from "../../assets/undraw_programming_re_kg9v.svg";
import raneti from "../../assets/people/raneti.jpg";
import popam from "../../assets/people/popescuam.jpeg";
import alindinita from "../../assets/people/alindinita.jpg";
import deliupasol from "../../assets/people/deliupasol.jpg";
import istrateana from "../../assets/people/istrateana.jpg";
import istrateciprian from "../../assets/people/istrateciprian.jpg";
import simonanicoara from "../../assets/people/simonanicoara.jpg";
import liviu from "../../assets/people/liviu.jpg";

export default function Organizators() {
  return (
    <div className="py-6 flex flex-col items-center">
      <Reveal>
        <label className="text-4xl lg:text-6xl font-light pb-6">
          Juriu
        </label>
      </Reveal>

      <Container className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-y-10 gap-x-5 p-10 ">
        <PersonCard imageURL={alindinita} name="Alin Diniță" position="Rector al Universitatii Petrol Gaze Ploiesti" motif="Domnul Alin Diniță (n. 1976) s-a născut în orașul Ploiești, a absolvit Școala nr. 29 din Ploiești și Liceul Industrial nr. 1 (actual Liceul Spiru Haret). În anul 1999 devine student al Facultății de Inginerie Mecanică și Electrică din cadrul Universității Petrol-Gaze din Ploiești. A absolvit studiile universitare de licență ca Șef de Promoție, apoi a urmat firesc studiile universitare de masterat și respectiv doctorat.<br/><br/> Cadru didactic al Universității Petrol-Gaze din Ploiești începînd cu anul 2004, a urcat treptele carierei didactice de la preparator universitar până la conferențiar universitar în cadrul Facultății de Inginerie Mecanică și Electrică. <br/>Cariera de management universitar a debutat cu functia de Prodecan al Facultății de Inginerie Mecanică și Electrică în perioada 2013-2016, Decan al aceleiași facultăți între anii 2016 – 2020 și Rector al Universității Petrol-Gaze din Ploiești 2022 – prezent." />
        <PersonCard imageURL={popam} name="Amalia Popescu" position="Mentor echipa Robotica ToTheStars Robotics" motif="Popescu Amalia, mentor echipa de robotică ToTheStars Robotics, Formator atestat Google, expert pe termen scurt în diverse proiecte.Am terminat Facultatea de Matematică- Informatică și master Tehnici avansate de prelucrarea a informației, am lucrat o perioadă ca lector la Catedra de Matematică, Universitatea Petrol şi Gaze Ploiești, iar în prezent sunt profesor de informatică la LTAS 'Victor Slăvescu' Ploiești."/>
        <PersonCard imageURL={istrateciprian} name="Ciprian Istrate" position="VP Bitdefender" motif="Istrate Ciprian Ștefan – Senior Vice President, Bitdefender România este absolvent al Universității Politehnica din București. Are o bogata experienta in Go-to-Product si Go-Market, stabilind strategia de produs si pozitionare in piata pentru portofoliul Bitdefender. Abilitatile lui de leadership  au contribuit la pozitionarea Bitdefender ca lider global in industria securitatii cibernetice.<br/><br/>In prezent coordoneaza strategia de brand, departamentul de operatiuni si este pasionat de mentoratul proiectelor de antreprenoriat."/>
        <PersonCard imageURL={raneti} name="Mihai Raneti" position="Fondator CyberSwarm" motif="Mihai Raneti - founder and CEO of CyberSwarm, an AI-enabling neuromorphic engineering company.Educated in both Psychology and Electromechanical Engineering, Mihai combines technical expertise with an understanding of human behavior to innovate in the field of AI.A strong supporter of innovation, creativity, and diversity in tech, he believes in a different approach: 'We take neuromorphic engineering to another level, harnessing nature’s intelligence to revolutionize AI.'Under his leadership, CyberSwarm is committed to creating a Swarm Nervous System that is not only technologically advanced but also grounded in ethics and aligned with strong values." />
        <PersonCard imageURL={istrateana} name="Mihaela Istrate" position="Mentor echipa Robotica RO2D2, cadru universitar Universitatea Romano- Americana" motif="Istrate Ana Mihaela – mentor la echipa de robotică Ro2D2 a Colegiului Național ”Mihai Viteazul” din Ploiești, este conferențiar universitar la Universitatea Româno-Americană din București unde susține cursuri de comunicare interculturală și management intercultural. A urmat stagii de training în domeniul comunicării interculturale în Marea Britanie, Polonia și Finlanda și a susținut module de curs la universități din Franța, Spania, Japonia, etc.<br/><br/>Este implicată în acțiuni de voluntariat în folosul comunității noastre și ajută la dezvoltarea personală a tinerilor pasionați de domeniul STEAM (Science, Technology, Engineering, Arts and Mathematics)."/>
        <PersonCard imageURL={deliupasol} name="Nicoleta Deliu Pașol" position="Head of Corporate Communication Banca Comerciala Romana" motif=" Nicoleta Deliu Pașol este Head of Corporate Communication and Community Affairs la Banca Comercială Română, face parte din Aspen Fellows Network al Institutului Aspen din România și a făcut un Global Executive MBA la Creative School of Leadership din Berlin. <br/><br/> Lucrează în domeniul comunicării de 18 ani, coordonând o serie de proiecte de anvergură, dintre care două dintre cele mai relevante sunt Școala de Bani (cel mai extins program de educație financiară pentru toate categoriile de vârstă) și cea mai eficientă platformă de donații individuale pentru sectorul non-guvernamental din România, Bursa Binelui. <br/><br/> Nicoleta este implicată într-o serie de acțiuni sociale și de voluntariat care o ajută să înțeleagă mai bine potențialul social și economic al României. Crede cu tărie în puterea educației de calitate de a forma profesioniști bine pregătiți și integri care să contribuie la dezvoltarea unei societăți competitive, unde munca și pasiunea sunt valorizate și răsplătite."/>
        <PersonCard imageURL={liviu} name="Liviu Popa" position="Elev olimpic" motif="Liviu Popa este elev la Colegiul National 'Mihai Viteazul', unde si-a descoperit si pasiunea pentru informatica si tehnologie astfel participant la o multime de competitii locale nationale si chiar internationale.<br/>Este implicat intr-o multime de proiecte care il ajuta sa inteleaga mai bine nevoile viitorului in domeniul tehnologiei."/> 
        <PersonCard imageURL={simonanicoara} name="Simona Nicoară" position="Conferentiar Universitar Universitatea Petrol Gaze Ploiesti" motif="Simona Nicoară este Conferentiar Universitar Universitatea Petrol Gaze Ploiesti unde sustine cursuri de Informatică și Calculatoare cat si Membru al centrului de cercetare CerTIMF." />
      </Container>
    </div>
  );
}
