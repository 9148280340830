import React from "react";
import Reveal from "../../components/Animation/Reveal";
import Container from "../../components/ui/Container";
import PersonCard from "../../components/ui/PersonCard";

import image from "../../assets/undraw_programming_re_kg9v.svg";
import iulian from "../../assets/people/iulian.jpg";
import pap from "../../assets/people/pap.png";
import misu from "../../assets/people/misu.jpeg";
import simionescu from "../../assets/people/simionescu.jpg";
import PersonCardSimple from "../../components/ui/PersonCardSimple";

export default function Organizators() {
  return (
    <div className="py-2 flex flex-col items-center">
      <Reveal>
        <label className="text-4xl lg:text-6xl font-light pb-6">
          Organizatori
        </label>
      </Reveal>

      <Container className="grid grid-cols-1 md:grid-cols-4 gap-y-10 gap-x-5 p-10 ">
        <PersonCardSimple imageURL={iulian} name="Iulian Nica" position="La Cocoș" /> 
        <PersonCardSimple imageURL={misu} name="Mihai Nicolae" position="Observatorul Prahovean" /> 
        <PersonCardSimple imageURL={simionescu} name="Radu Simionescu" position="Președinte EuroSpirit"  /> 
        <PersonCardSimple imageURL={pap} name="Ciprian Pap" position="Observatorul Prahovean" /> 
      </Container>
    </div>
  );
}