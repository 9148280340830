import React from 'react';
import {useState} from "react";
import Reveal from '../../components/Animation/Reveal';
import Container from '../../components/ui/Container';
import SlideFromBottom from '../../components/Animation/SlideFromBottom';

const calendar = [
  {
    date: '2',
    month: 'Noiembrie',
    title: 'FINALIZAREA ÎNSCRIERII CONCURENȚILOR',
    content: [],
  },
  {
    date: '3',
    month: 'Noiembrie',
    title:
      'SOSIREA PARTICIPANȚILOR ȘI PRELUAREA DE CĂTRE VOLUNTARI',
    content: [],
  },
  {
    date: '4',
    month: 'Noiembrie',
    title: 'PRIMA ZI A COMPETIȚIEI',
    content: [
      { hour: '08:00', title: 'Deschiderea oficială' },
      { hour: '08:30', title: 'Trimiterea subiectelor către participanți' },
      { hour: '16:00', title: 'Workshop 1 : To be announced' },
      { hour: '20:00', title: 'Subiecte BLITZ' },
      { hour: '**:**', title: 'Surprise event :) ' },
    ],
  },
  {
    date: '5',
    month: 'Noiembrie',
    title: 'A DOUA ZI A COMPETIȚIEI',
    content: [
      { hour: '08:00', title: 'Status meeting' },
      { hour: '10:00', title: 'Workshop 2 : To be announced' },
      { hour: '12:00', title: 'Închiderea competiției' },
      { hour: '12:30', title: 'Jurizare' },
      { hour: '18:30', title: 'Festivitatea de premiere' },
      { hour: '20:00', title: 'Concluzii, încheiere' },
    ],
  },
];

export default function Schedule() {
  const [showMore, setShowMore] = useState(false);
  const calendarContent= (      
  <div className="flex flex-col gap-4 md:gap-8 pt-8 w-full">
      {calendar.map((schedule: ScheduleProps) => {
        return (
          <div key={Math.random() * 1000}>
            <ScheduleCard schedule={schedule} />
          </div>
        );
      })}
    </div>);
  return (
    <Container
      className="py-16 flex flex-col items-center
    "
    >
      <Reveal>
        <label className="font-bold uppercase text-md text-primary">
          2 Noiembrie - 5 Noiembrie
        </label>
      </Reveal>
      <Reveal>
        <label className="text-3xl lg:text-6xl font-light pb-2">
        Calendarul Competiției
        </label>
      </Reveal>
      <Reveal>
      <label className="text-3xl lg:text-6xl font-light pb-2">
          <button className="bg-primary py-2 font-normal w-[20em] px-20 text-4xl w-fit h-fit py-1 text-white rounded-md" onClick={() => setShowMore(!showMore )}>{showMore ? "Detalii" : "Detalii " }</button>
        </label>
      </Reveal>
     {(showMore? calendarContent : "")}
    </Container>
  );
}

const ListItem = ({ hour, title }: { hour: string; title: string }) => {
  return (
    <li className="flex flex-row gap-4 text-start items-baseline w-full justify-start ms-2">
      <span className="bg-primary px-2 w-fit h-fit py-1 text-white rounded-md">
        {hour}
      </span>
      <label>{title}</label>
    </li>
  );
};

interface ScheduleProps {
  date: string;
  title: string;
  content: any[];
}

const ScheduleCard = ({ schedule }: { schedule: any }) => {
  const [showMore, setShowMore] = useState(false);
  var countContent=0;
  var contentMap = schedule.content.map((x: any) => {
    countContent+=1;
    return (
      <div key={x.title}>
        <ListItem hour={x.hour} title={x.title} />
      </div>
    );
  });
  return (
    <SlideFromBottom>
      <div className="bg-white relative flex flex-col md:flex-row py-4 px-8 w-full h-full items-start md:items-center shadow-lg gap-12 md:gap-16">
        <div className="flex md:flex-col gap-0 md:items-center">
          <label className={'text-primary text-4xl mr-3 md:text-5xl font-bold md:font-semibold '}>
            {schedule.date} 
          </label>
          <label className="text-4xl md:text-lg inline-block text-primary font-bold">
            {schedule.month}
          </label>
        </div>
        <div className="absolute top-20 bg-primary md:top-auto md:left-40 h-[1px] w-[85%] md:h-[75%] md:w-[2px] s-primary my-1" />
        <div className="w-full flex flex-col gap-2">
          <label className=" text-xl md:text-2xl font-bold ms-2 uppercase text-black/75 basis-1/4">
            {schedule.title}
          </label>
              {showMore ? (<ul className="w-full flex flex-col gap-2"> {contentMap} </ul>): ""}
          <label className="w-full">
            {(countContent >2 ) ? (<button className="text-base font-normal w-[20em] bg-primary px-2 w-fit h-fit py-1 text-white rounded-md" onClick={() => setShowMore(!showMore )}>{showMore ? "DETALII ⯅" : "DETALII ⯆" }</button>) : "" }
          </label>
        </div>
      </div>
    </SlideFromBottom>
  );
};
