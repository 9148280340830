import React from "react";
import Container from "../../components/ui/Container";
import Button from "../../components/ui/Button";
import Reveal from "../../components/Animation/Reveal";

import { ReactComponent as Image } from "../../assets/undraw_proud_coder_re_exuy.svg";
import SlideFromBottom from "../../components/Animation/SlideFromBottom";

export default function Tema() {
  return (
    <div className="relative">
      <Container className="flex flex-col items-center py-3 text-center relative" id="theme">
        <Reveal>
          <div className="flex flex-col gap-2 pt-8 w-full pb-0 [&>p]:text-justify text-center text-black/80 text-lg md:text-xl">
            <label className="text-4xl lg:text-6xl font-light pb-6"> Tema competiției</label>
            <p className="w-[100%] ">
                Aflat la prima ediție, Code Envision își dorește să aducă în același loc un număr cât mai mare de pasionați de 
                programare cu vârste cuprinse între 16 și 25 de ani cu scopul de a identifica
                și programa/rezolva soluția la o problemă locală a comunității care îl găzduiește.
            </p>
            <p>
                Cum poluarea este principala problemă a cetățenilor municipiului Ploiești
                , ea nu putea fi ignorată de organizatori.
            </p>
            <p>
                Tema acestei ediții a maratonului de programare este identificarea unei 
                soluții informatice pentru realizarea unei a unei rețele de dispozitive
                IoT (Internet of Things), care să monitorizeze evoluția principalilor 
                agenți de poluare din oraș.
            </p>
            <p>
                Intenția este aceea de a crea un sistem complementar sistemului actual 
                de stații de monitorizare a calității aerului gestionat de Agenția pentru
                Protecția Mediului Prahova, inclusiv prin măsurarea unor agenți poluatori
                pe care stațiile actuale nu îi identifică.
            </p>
            <p>
                Provocarea pentru participanții la maraton este aceea de a crea un sistem care,
                pe lângă monitorizarea calității aerului, să poată oferi și internet gratuit 
                utilizatorilor aflați în aria de acoperire a dispozitivelor, astfel încât acestea
                să poată transmite utilizatorilor informații utile și alerte referitoare.
            </p>
          </div>
        </Reveal>
      </Container>
    </div>
  );
}
