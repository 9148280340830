import React from "react";
import { useState} from "react";
import SlideFromBottom from "../Animation/SlideFromBottom";
export default function TVCard({
  imageURL,
  mediaURL,
}: {
  imageURL: string;
  mediaURL: string;
}) {
  return (
    <SlideFromBottom className="md:col-start-2 col-start-1">
      <div className="w-full h-full px-4 pt-8 pb-8 bg-white rounded-md shadow-xl flex flex-col items-center text-black gap-2 transition-all cursor-pointer hover:scale-105">
        <div className="relative w-[100%] h-0 pb-[30%] overflow-hidden">
          <img className="absolute left-0 top-0 w-[100%]" src={imageURL} alt=""/>
        </div>
        <div className="h-[1px] border border-black/5 w-[75%] my-3" />
        <a className="font-bold text-center text-xl text-blue-600 uppercase" href={mediaURL}>
            Accesează
        </a>
      </div>
    </SlideFromBottom>
  );
}
