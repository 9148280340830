import React, { useEffect } from "react";
import lacocos from "../../assets/sponsors/lacocos.jpg";
import Container from "../ui/Container";

export default function Header() {
  useEffect(() => {
    var prevScrollpos = window.pageYOffset;
    window.onscroll = function() {
    var currentScrollPos = window.pageYOffset;
      if (prevScrollpos > currentScrollPos) {
        document.getElementById("navbar")!.style.top = "0px";
      } else {
        document.getElementById("navbar")!.style.top = "-70px";
      }
      prevScrollpos = currentScrollPos;
    }
  }, []);
  return (
    <div id="navbar" className="bg-white sticky w-full z-[100] transition-all">
      <Container>
        <img className="h-[4rem] mx-auto" src={lacocos} alt="lacocos" />
      </Container>
    </div>
  );
}
